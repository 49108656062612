// External Modules
import { lazy } from 'react';
import { Outlet, Routes, Route, Navigate } from 'react-router-dom';

// Internal Modules
import loadSdk, { decodeBase64, people, reportError } from '@apolitical/sdk';

import { GlobalProviders, PageLayout } from '@apolitical/component-library';

// Component Library Styles
import '@apolitical/component-library/style.css';

// Pages
const SearchPage = lazy(() => import('./Pages/SearchPage'));
const Home = lazy(() => import('./Pages/Home'));
const LearningHub = lazy(() => import('./Pages/LearningHub'));

const messages = require('../locales/en.json');

function App() {
  async function bootstrap() {
    // Load the SDK
    loadSdk({
      contentContext: {
        apiKey: decodeBase64(process.env.REACT_APP_CDA_API_KEY),
      },
      errorsContext: {
        apiKey: decodeBase64(process.env.REACT_APP_GCP_ERRORS_API_KEY),
        serviceName: 'core-pages',
      },
      gtmContext: {
        configs: JSON.parse(decodeBase64(process.env.REACT_APP_GTM_ID)),
      },
      googleMapsContext: {
        apiKey: decodeBase64(process.env.REACT_APP_GOOGLE_MAPS_API_KEY),
      },
      origin: window.origin || window.location.origin,
    });
    // Fetch logged-in user (/me)
    const me = await people.users.read('me');
    // It must return the user object
    return me;
  }

  return (
    <GlobalProviders bootstrap={bootstrap} intl={{ messages }}>
      <Routes>
        <Route
          element={
            <PageLayout
              reportError={reportError}
              suprSendWorkspaceKey={process.env.REACT_APP_SUPRSEND_WORKSPACE_KEY}
              styling={{ background: 'gradient' }}
            >
              <Outlet />
            </PageLayout>
          }
        >
          <Route path="/search" element={<SearchPage />} />
        </Route>

        <Route
          element={
            <PageLayout
              reportError={reportError}
              suprSendWorkspaceKey={process.env.REACT_APP_SUPRSEND_WORKSPACE_KEY}
            >
              <Outlet />
            </PageLayout>
          }
        >
          <Route path="/home" element={<Home />} />
          <Route path="/learning-hub/:slug" element={<LearningHub />} />
          <Route path="/" element={<Navigate to="/home/" />} />
        </Route>
      </Routes>
    </GlobalProviders>
  );
}

export default App;
